@font-face {
    font-family: 'Hacker Noon V2';
    src :  url("../../public/HackerNoonV2.ttf")format('truetype'),
    url("../../public/HackerNoonV1.ttf")format('truetype')
}

*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    font-family: "IBM Plex Mono", monospace;
    padding: 0px;
    margin: 0px;
}
/*GRID PARAMS*/
.app_wrapper {
    display: grid;
    grid-template-areas:
    "header header header  "
    "sidebar sidebar sidebar"
    "main  main main  "
    "footer footer footer  "
;
    height: 100vh;
    grid-template-columns: 1fr 4fr 1fr;
}

@media (max-width: 1025px) {
    .app_wrapper {
        display: block;
    }
}

/*HEADER*/
.header {
    background-color: rgb(0, 255, 0);
    grid-area: header;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*MAIN BODY */
.main{
    grid-area: main;
    display: flex;
    justify-content: space-around;
    background: rgb(60, 60, 59);
}
/*MAIN BODY CONTAINER*/
.container_main{
    width: 1200px;
    max-width: 100%;
    font-size: 18px;
}
/*FOOTER*/
.footer {
    background-color: rgb(0, 255, 0);
    grid-area: footer;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 30px 15px 30px 15px;
}

/*ELEMENTS WITH A SAME STYLE*/

button {
    background-color: rgb(33, 36, 40);
    color: rgb(0, 255, 0);
    padding: 10px 20px;
    border: 4px solid rgb(0, 255, 0);
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}
button:hover {
    background-color: #45a049;
}

h2{
    color : white;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: bold;
}
h2::before {
    margin-right: 30px;
    content : "";
    flex: 1 1 0%;
    border-bottom: 3px solid rgb(37, 36, 36);
}
h2::after {
    margin-left: 30px;
    content : "";
    flex: 1 1 0%;
    border-bottom: 3px solid rgb(37, 36, 36);
}


h3 {
    font-weight: bold;
}
img {
    /*box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;*/
}
input , textarea{
    outline: none;
    border: none;
}




