.profile_container{
    padding: 20px;
}
/*Main container  */
.profile_section{
    margin-top: 40px;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px, rgb(133, 149, 173) 0.2em 0.4em, rgb(133, 149, 173) 0.4em 0.2em, rgb(82, 98, 122) 0.4em 0.6em, rgb(82, 98, 122) 0.6em 0.4em;
}
/*Container for both sections AVATAR and ABOUT ME*/
.profile_info_section{
    /*border: 2px solid red;*/
    display: flex;
    justify-content: space-evenly;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}

/*AVATAR SECTION*/
/*Container For AVATAR*/
.profile_avatar_container {
    /*border: 2px solid blue;*/
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    /*width: 20%;*/
    background-color: rgb(246, 247, 249);
    height: fit-content;
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-left: 50px;
    padding: 30px;
}
/*Status Section in AVATAR */
.status_container{
    padding-top: 10px;
    padding-bottom: 10px;
}
.status_container span:hover {
    background-color: rgba(0, 255, 0, 0.35);
}

/*-------------------------------------------------------------------------------------------------------------------------------*/

/*CONTACTS - ABOUT ME SECTION*/
/*Main Container for contacts USERS and ADMIN*/
.profile_data_container{
    /*border: 2px solid black;*/
    margin-left: 90px;
}
/*Wrapper to sepparate User Info and Contacts - FLEX - SPACE-BETWEEN */
.profile_about_me_section {
    flex-direction: column;
    display: flex;
    gap: 30px;
}
.profile_user_info_section{
    /*border: 2px solid black;*/
}
/*// LIST OF USER INFO*/

.profile_list_section{
    /*margin: 0;*/
    padding: 10px;
}
.profile_list_section ul{
    padding-left: 0;
}
.profile_list_section li {
    list-style: none;
    padding-top: 20px;
}
.profile_list_section span {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 93%;
    /*margin: 10px;*/
    display: inline-block;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
}

.profile_list_section  textarea,
.profile_list_section  input
{
    background-color: rgb(200, 207, 218);
    width: 90%;
    border-radius: 2px;
    padding: 10px;
    font-weight: bold;
    border: none;
    resize: none;
}

.profile_avatar_img img{
  width: 180px;
}



/*CHECKBOX*/

/*CONTACTS LIST */
.contacts_list_section{
    /*border: 2px solid red;*/
}
.contacts_form_list{
    margin-top: 10px;
    margin-bottom: 10px;
}
.contacts_form_list span{
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.contact_form_title{
    background-color: rgb(194, 202, 214);
}

/*BUTTON TO EDIT FORM*/
.edit_form_btn{
    display: flex;
    justify-content: space-evenly;
}

.edit_form_btn_section {
    margin-top: 30px;
    margin-bottom: 20px;

}

.edit_form_btn button {
    padding: 15px 60px 15px 60px;
    font-size : 15px
}

/*Profile header section*/
.about_me_title{
    background-color: rgb(194, 202, 214);
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: 24px;
    margin: 0;
}




/* Responsive styles for medium screens */

@media  (max-width: 900px) {
    .profile_info_section {
        flex-direction: column;
        padding: 0;
        /*align-items: center;*/
    }
    .profile_avatar_img img{
        width: 250px;
    }
    .photo_input_section{
        margin: 0 auto;
    }
    .custom-file-input{
        margin: 0 auto;
        display: flex;
    }

    .profile_user_name{
        margin-bottom: 10px;
        color: black;
        margin-top: 10px;
    }

    .profile_avatar_container {
        margin-left: 0;
        box-shadow : none;
    }
    .profile_about_me_section{
        justify-content: center;
    }
    .contact_form_title{
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 0;
        text-align: center;
        font-size: 24px;
    }

    .user_info_title{
        margin-bottom: 15px;
        margin-left: 10px;
    }
    .edit_form_btn_section{
        text-align: center;
    }

    .profile_data_container {
        margin-left: 0;
    }
}

@media  (max-width: 700px) {
    .profile_avatar_img img{
        width: 250px;
    }

    .contacts_list_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .profile_about_me_section{
        display: flex;
        flex-direction: column;
        padding: 20px;
        /*align-items: center;*/
    }
    .user_info_title{
        margin-bottom: 15px;
        text-align: center;
    }
    .user_contacts_title{
        text-align: center;
        /*margin: 0;*/
    }
    .contacts_list_section{
        /*padding: 0;*/
    }
    .photo_input_section{
        width: 250px;
    }
    .edit_form_btn_section button{
        font-size: 16px ;
        margin-bottom: 20px;
    }
    .custom-file-input{
        max-width: 140px;
        width: 100%!important;
    }
}


@media  (max-width: 420px) {
    .profile_list_section span {
        display: block; /* Display each span as a block element */
    }
    .contacts_list_section span{
        display: block;
    }
    .photo_input_section{
        width: 170px;
        padding: 5px;
    }
    .edit_form_btn_section{
        margin-top: 10px;
    }
    .custom-file-input{
        max-width: 80%;
    }
    .profile_avatar_img img{
        width: 235px;
    }

    .post_image_edit_section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .photo_uploader_section {
        margin-top: 20px!important;
    }

}


@media  (max-width: 420px) {
    .photo_input_section{
        width: 140px;
        padding: 5px;
    }
}