.sidebar {
    background-color: rgb(19, 21, 19);
    grid-area: sidebar;
}

.sidebar ul {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
}

.sidebar_list a {
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bold;
    font-size: 24px;
    list-style-type: none;
    text-decoration: none;
    padding: 10px;
    /* Added padding for better touch target */
}

.sidebar_list a:hover {
    background-color: rgb(0, 255, 0, 0.80);
}

/* MOBILE */
.burgerMenuEnabled {
    overflow-y: hidden;
}

@media (min-width: 1025px) {
    .header_burger_menu {
        display: none;
    }

    .sidebar_mobile_show {
        display: none;
    }
}

@media (max-width: 1025px) {

    .header {
        position: sticky;
        top: 0;
        z-index: 1000;
    }

    .sidebar {
        display: none;
    }

    .sidebar_show {
        transform: translateX(-100%);
        transition: transform .2s ease-in-out;

        width: 100%;
        height: 100%;

        pointer-events: none;

        position: fixed;
        top: 0px;
        left: 0;
        display: flex;
        flex-direction: column;
        /* Stack items vertically */
        align-items: flex-start;
        /* Align items to the start of the container */
        z-index: 1000;
        background: rgb(33, 36, 40);


    }

    .sidebar_show-active {
        transform: translateX(0%);
        pointer-events: auto;
        overflow-y: hidden;
    }

    .sidebar_show ul {
        list-style-type: none;
        margin: 20px;
        padding: 0;
    }

    .sidebar_show li {
        margin-top: 15px;
    }

    .sidebar_list a {
        font-weight: bold;
        font-size: 24px;
    }

    .header_burger_menu img {
        width: 30px;
        position: absolute;
        top: 30px;
        left: 20px;
        z-index: 1001;
        cursor: pointer;
    }

    .close_mobile_menu {
        background: rgb(0, 255, 0);
        padding: 2px;
        border-radius: 50%;
    }
}

/* LOGIN */

@media (min-width: 840px) {

    .user_logout img {
        display: none;
    }
}

@media (max-width: 840px) {
    .user_logout button {
        display: none;
        position: relative;
        width: 50px;
    }

    .user_logout img {
        position: absolute;
        width: 30px;
        right: 10px;
        top: -15px;
    }
}

/* Media query for screens with max-width 570px */
@media (max-width: 580px) {
    .header_logo_text {
        font-size: 24px;
    }

    .header_logo_text img {
        width: 30px;
    }

    .header_login button {
        font-size: 15px;
        padding: 8px;
    }

    .header_burger_menu img {
        width: 30px;
        top: 18px;
    }

    .user_logout img {
        width: 30px;
        top: -15px;
    }

    .sidebar_show ul {
        margin: 20px;
    }
}

@media (max-width: 460px) {
    .header_logo_text {
        font-size: 21px;
    }
}

@media (max-width: 400px) {
    .header_login {
        right: 10px;
    }

    .header_burger_menu img {
        width: 25px;
        left: 10px;
        top: 20px;
    }

    .header_logo_text img {
        width: 27px;
    }

    .header_logo_text {
        font-size: 17px;
    }

    .header_login button {
        padding: 3px;
        font-size: 13px;
    }

    .user_logout img {
        width: 25px;
        top: -13px;
    }
}

.sidebar_show ul {
    width: 100%;
    margin: 0;
    margin-top: 50px;
}

.sidebar_show li {
    background-color: rgb(60, 60, 59);
    border-radius: 5px;
    border: 2px solid rgba(0, 255, 0, .1);
}

.sidebar_show li:active {
    border: 2px solid rgb(0, 255, 0);;
}

.sidebar_show {
    box-sizing: border-box;
    padding: 20px;;
}

@media (max-width: 580px) {
    .sidebar_show ul {
        margin-top: 40px;
    }
}
@media (max-width: 390px) {
    .sidebar_show {
        padding: 10px;
    }
}