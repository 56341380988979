/*EIDT SECTION*/
.edit_mode_container {
    margin-bottom: 20px;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px, rgb(133, 149, 173) 0.2em 0.4em, rgb(133, 149, 173) 0.4em 0.2em, rgb(82, 98, 122) 0.4em 0.6em, rgb(82, 98, 122) 0.6em 0.4em;
    padding: 10px calc(10px + 0.2rem);
    background-color: #fff;
}

.edit_post_info_section {
    padding: 10px;
    /*border: 2px solid red;*/
}

.edit_post_info_section textarea,
.edit_post_info_section input {
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: rgb(194, 202, 214);
    width: 90%;
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    border: none;
    resize: none;
    padding: 20px;
}
.edit_title{
}
.edit_content_title {
}


.post_image_edit_section {
    display: flex;
    justify-content: space-around;
}

.post_image img{
    max-width: 150px    ;
    height: auto;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.photo_uploader_section {
    /*border: 2px solid blue;*/
    margin-top: 90px;
}



.edit_save_button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.edit_save_button button {
    padding: 15px 30px 15px 30px;
}


