

.header_logo_text {
    display: flex;
    align-items: center;
    font-family: 'Hacker Noon V2';
    font-weight: bold;
    font-size: 24px;
    color: black;
}

.header_logo_text img {
    margin-right: 5px;
    margin-left: 5px;
    width: 50px;
}

.header_login {
    position: absolute;
    right: 20px; /* Adjust the distance from the right edge */
}

.header_login button {
    font-size: 1rem;
    font-weight: bold;
    /*margin-right: 1rem;*/
    border: 2px solid rgb(60, 60, 59);
    background: rgb(0, 255, 0);
    color: rgb(60, 60, 59);
    cursor: pointer;
}
.header_login button:hover {
    color: rgb(0, 255, 0);
    background-color: rgb(60, 60, 59);;
}

.active{
    box-sizing: inherit;
    text-decoration: none !important;
}


#loginBtn img {
    width: 35px;
    height: 35px;
}

.header {
    display: flex;
    align-items: center;
}

@media (max-width: 1025px) {


    #loginBtn {
        border: none;
        padding: 0;
    }
}
@media (max-width: 580px) {
    #loginBtn img {
        translate: -5px 2px;
        width: 30px;
        height: 30px;
    }
}
@media (max-width: 400px) {
    #loginBtn img {
        width: 25px;
        height: 25px;
    }
}