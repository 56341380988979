.new_post_container {
    border-radius: 3px;
    background-color: rgb(0, 0, 1);
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px, rgb(133, 149, 173) 0.2em 0.4em, rgb(133, 149, 173) 0.4em 0.2em, rgb(82, 98, 122) 0.4em 0.6em, rgb(82, 98, 122) 0.6em 0.4em;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.create_post_title {
    color: white;
    text-align: center;
}
.change_content textarea,
.change_title input {
    background-color: rgb(194, 202, 214);
    width: 90%;
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    border: none;
    resize: none;
    padding: 20px;
}

.upload_image_section {
    /*border: 2px solid red;*/
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}
.select_image_title {
    font-size: 21px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.post_image{
    max-width: 150px    ;
    height: auto;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.post_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}


.create_new_post_info_section textarea,
.create_new_post_info_section input {
    margin-top: 20px;
    background-color: white;
    width: 95%;
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    border: none;
    resize: none;
    padding: 20px;
}



@media (max-width: 650px) {
    .create_new_post_info_section textarea, .create_new_post_info_section input{
        width: 90%;
    }
}

@media (max-width: 450px) {
    .create_new_post_info_section textarea, .create_new_post_info_section input{
        width: 85%;
    }
}
