.open_ai_container {
    margin-top: 30px;
    margin-bottom: 50px;
}

.openai_section {
    margin: 0 auto;
    background-color: white;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px, rgb(133, 149, 173) 0.2em 0.4em, rgb(133, 149, 173) 0.4em 0.2em, rgb(82, 98, 122) 0.4em 0.6em, rgb(82, 98, 122) 0.6em 0.4em;
    width: 70%;
    height: 600px;
    /*overflow-y: scroll;*/
}

.title {
    background-color: rgb(194, 202, 214);
    text-align: center;
    padding-bottom: 1px;
    padding-top: 1px;
    font-weight: bold;
}

.response_section {
    height: 350px;
    padding: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
}

.openai_inner_section {
    position: relative;
    background-color: rgb(200, 207, 218);
    height: 120px;
}

.openai_inner_section textarea {
    border: 2px solid white;
    border-radius: 5px;
    background-color: rgb(200, 207, 218);
    resize: none;
    font-size: 18px;
    resize: none;
    padding: 10px 80px 10px 10px;
    height: 60px;
    width: 100%;
}

.openai_inner_section button {
    position: absolute;
    right: 5px;
    bottom: 30px;
    border: none;
    background-color: transparent;
}

.input-container {
    display: flex;
    padding: 10px;
}

.submit-button img {
    width: 40px;
    margin-right: 20px;
}


.message_wrapper {
    display: grid;
}

.floodchat_message {
    position: relative;
    display: grid;
    width: fit-content;
    padding: 7px 15px;
    background-color: #dad9d9;
}

.floodchat_message p {
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
}

.floodchat_message_inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.floodchat_noImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #a3a3a3;
}

.floodchat_message::after {
    position: absolute;
    bottom: 0px;
    left: -5px;
    content: "";
    display: block;
   
    width: 0px;
    height: 0px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 8px solid rgb(218, 217, 217);
}

.message_box {
    position: relative;
    padding: 7px 15px;
    border-radius: 5px;
}

.message_box::after {
    position: absolute;
    bottom: 0px;
    content: "";
    display: block;
   
    width: 0px;
    height: 0px;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
}

.openai-userMsg {
    background-color: rgb(0, 255, 0);
    width: fit-content;
    border-bottom-left-radius: 0px;
}

.openai-userMsg::after {
    border-bottom: 8px solid rgb(0, 255, 0);
    left: -5px;
}


.openai-assistantMsg{
    background-color: rgb(218, 217, 217);
    width: fit-content;
    justify-self: flex-end;
    border-bottom-right-radius: 0px;
}

.openai-assistantMsg::after {
    border-bottom: 8px solid rgb(218, 217, 217);
    right: -5px;
}

@media (max-width: 1025px) {
    .open_ai_container {
        margin-top: 0px;
        margin-bottom: 0px;

        height: calc(100vh - 90px);

        display: flex;
        align-items: center;
    }
}

@media (max-width: 850px) {
    .open_ai_container {
        height: calc(100vh - 90px);
    }

    .openai_section {
        width: 100%;
        height: 100%;
        box-shadow: none;

        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    .response_section {
        height: 100%;
    }

    .openai_inner_section {
        width: 100%;
        height: auto;
    }



    .openai_inner_section .submit-button {
        bottom: auto !important;
        width: 40px;
        top: 50%;
        translate: 0 -50%;
        margin-right: 20px;
    }
}

@media (max-width: 580px) {
    .open_ai_container {
        height: calc(100vh - 70px);
    }
}


@media (max-width: 550px) {

    .openai_inner_section .submit-button {
        padding: 5px;
    }

    .response_section {
        padding: 20px 10px;
    }

    .openai_inner_section textarea {
        padding-right: 60px;
    }

    .open_ai_container {
        /*margin: 0;*/
    }

    .openai_section {
        /*width: 100%;*/
        font-size: 15px;
    }

    .response_section {
        font-size: 14px;
    }

    .input-container {
        font-size: 14px;
    }

    .submit-button img {
        width: 30px;
        margin-right: 5px;

    }

    .openai_inner_section button {
        bottom: 35px;
        right: 0;
    }

    .openai_inner_section textarea {
        font-size: 14px;
    }

}

@media (max-width: 400px) {
    .open_ai_container {
        height: calc(100vh - 67px);
    }
}

