.formik_container{
    display: flex;
    align-items: center;
    gap: 20px;
}

.formik_container input{
    background-color: white;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    border: none;
    resize: none;
}

.custom-select-container {
    position: relative;
    display: inline-block;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
}
.custom-select-container select{
    padding-right: 30px;
}

/* Style the custom select */
.formik-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid rgb(194, 202, 214);
    border-radius: 2px;
    padding: 5px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
}

/* Style the dropdown options */
.formik-select option {
    background-color: white;
    font-weight: bold;
}

/* Style the custom dropdown arrow */
.custom-select-container::after {
    content: '\25BC'; /* Unicode character for down arrow ▼ */
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
}

.formik_title{
    /*color: white;*/
}

/*MOBILE*/

@media (max-width : 650px) {
    .formik_container{
        display: flex;
        align-items: flex-start;
        width: 200px;
        flex-direction: column;
        gap: 20px;
    }
}
