/*USER CONTAINER SECTION*/
.user_container{
    padding: 5px;
}

.find_section{
    margin-left: 40px;
}

.users_section {
    margin-top: 20px;
    /*border: 3px solid green;*/
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
/*USER BLOCK IMG NAME BTN SECTION*/

.user_section_box {
    /*border: 2px solid red;*/
    background-color: #1a1919;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 2px solid grey;
    width: 260px;
    /*height: 360px;*/
    margin: 10px 10px 30px 10px;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
}


.user_name {
    margin-bottom: 10px;
    color: white;
    font-weight: bold;
    text-align: center;
}

.followed_section {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.start_chat_section {
    text-align: center;
}

.pagination_section {
    /*border: 2px solid white;*/
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}




.formik_users_title{
    color: white;
}

@media (max-width: 450px) {
    .user_section_box{
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .followed_section{
        margin-top: 0;
    }

}