/**Current posts section*!*/
/*mapped posts items content - 1 block - 1 post */
[class^="current_post_items_"] {
    margin-bottom: 40px;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px, rgb(133, 149, 173) 0.2em 0.4em, rgb(133, 149, 173) 0.4em 0.2em, rgb(82, 98, 122) 0.4em 0.6em, rgb(82, 98, 122) 0.6em 0.4em;
    /*padding: 10px calc(10px + 0.2rem);*/
    background-color: #fff;
}

/*Container for current posts*/
.current_posts_container {
    display: flex;
    justify-content: space-between;
}

/*LEFT SIDE OF CONTAINER*/
.current_post_image {
    margin-left: 20px;
    margin-top: 20px;
}

.current_post_image img {
    max-width: 150px;
    height: 150px;
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
}

/*RIGHT SIDE OF CONTAINER*/
.current_post_content {
    /*border: 2px solid red;*/
    margin: 20px 30px 20px 30px;
}

.current_post_detail {
    padding-bottom: 20px;
}

.photo_uploader_section {
    /*border: 2px solid blue;*/
    margin-top: 90px;
}

/*Button to edit or Create a new post*/
.current_post_buttons {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 8px;
}

.current_post_buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


@media (max-width: 650px) {
    .current_posts_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .current_post_image {
        margin-left: 0;
    }

    .current_post_buttons button {
        padding: 10px;
    }
    .photo_input_section {
        display: none;
    }
}