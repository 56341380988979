.container_404{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 700px;
    width: 80%;
}
.title_404{
    cursor: pointer;
    color: black;
    font-size: 110px;
    text-align: center;
    background-image: linear-gradient(transparent 0%, transparent calc(50% - 9px), rgba(0, 255, 0, 0.35) calc(50% - 9px), rgba(0, 255, 0, 0.35) 100%);
    background-size: 100% 200%;
    font-weight: bold;
}

.title_404:hover {
    background-color: rgb(0, 255, 0);
}




@media (max-width: 700px) {
    .title_404{
        font-size: 70px;
    }
}

@media (max-width: 430px) {
    .title_404{
        font-size: 50px;
    }
}