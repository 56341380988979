.pagination_container{
    display: flex;
    gap: 20px;
}

.pagination_container button{
    padding: 10px 10px 10px 10px;
    width: 20px;
    height: 20px;
}

.pages_section{
    text-align: center;
    margin-top: 15px;
}

.pages{
    margin: 0 auto;
    padding-right: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}


.currentPageColor {
    color: rgb(0, 255, 0);
}