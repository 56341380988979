.news_container {
    padding: 10px;
}

.news-section {
    font-weight: bold;
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    gap: 2px;
    margin: 0px auto;
}

.news-section a {
    color: white;
    text-decoration: none;
}

*, ::before, ::after {
    box-sizing: inherit;
}

/*Attribute selector with the caret (^=) targets any class that starts with "story-card_". It accommodates the dynamic nature of your class names
because we got :  className={`story-card_${index + 1}`} in JSX
.*/
[class^="story-card_"] {
    background-color: rgb(19, 21, 19);
    /*border: 3px solid rgb(0, 255, 0);*/
    border :3px solid rgb(79 94 80);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.title_wrapper {

    display: flex;
    position: relative;
    align-self: flex-start;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
    height: 100px;
    padding: 20px;
    font-size: 20px;
}

.card_reaction {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    font-family: "IBM Plex Mono", monospace;
    font-size: 11px;
    max-height: 30px;
    margin-top: auto;
    color: rgb(60, 60, 59);
}

.image-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    cursor: pointer;
    border-radius: 2px;
}

.image-wrapper  {
    display: block;
    height: 200px;
    width: 100%;
}

.image-wrapper > a.span-3 {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 300px;
}

.img_span img {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: block;
}

.tag {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 0;
    border: 2px solid;
    padding: 5px;
    margin-right: 20px;
    margin-bottom: 5px;
    background-color: rgb(9 104 20);
}


.card_comments {
    font-size: 1.4rem;
    color: rgb(60, 60, 59);
    margin: 0 auto;
}

.meta {
    padding: 20px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.profile {
    -webkit-box-align: center;
    align-items: center;
}

.profile-container {
    width: 50px;
    height: 50px;
    position: relative;
}

.news_pre_loader {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.robot_style {
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    max-width: 50px;
}

.author {
    background-color: rgb(9 104 20);
    margin-top: 10px;
}

@media (max-width: 800px) {
    .news-section {
        display: block;
    }
}