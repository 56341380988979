.drop_area {
    background-color: #0f0 !important;
}
.photo_input_section{
    display: flex;
    background-color: white;
    justify-items: center;
    flex-direction: column;
    border : 2px solid #003b00;
    border-style : dashed;
    text-align: center;
    padding: 10px;
}
.custom-file-input {
    margin-top: 10px;
    display: flex;
    width: 100%  ;
    color: transparent;
}
.custom-file-input{
    justify-content: center;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    text-align: center;
    content: 'Upload image';
    display: inline-block;
    color: black;
    font-weight: bold;
    background-color: #0f0!important;
    border: 2px solid #003b00!important;
    border-radius: 3px;
    padding: 8px 11px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-size: 10pt;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active {
    outline: 0;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
