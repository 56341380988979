.users_photo {
    background: white;
}
.selected_page {
    font-weight: bold;
}

.avatar_img img{
    box-shadow: rgb(194, 202, 214) 0px 0.2em, rgb(194, 202, 214) 0px -0.2em, rgb(194, 202, 214) 0.2em 0px, rgb(194, 202, 214) -0.2em 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 110px;
}